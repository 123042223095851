import { Box, Button, Container, Text, useDialogState, useToast } from '@color/continuum';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useColorQuery, usePatchWithAuth } from 'lib/api';
import { Report } from 'lib/types';

import { OnHoldDialog } from './OnHoldDialog';
import { RemoveHoldDialog } from './RemoveHoldDialog';
import { SignoutDialog } from './SignoutDialog';

export const Footer: React.FC = () => {
  const { id: reportId } = useParams<{ id: string }>();
  const [queryKey, setQueryKey] = useState(0);
  const { isLoading, isError, error, data: report } = useColorQuery<Report>(
    `/reports/${reportId}`,
    {},
    queryKey
  );
  const refetch = () => setQueryKey(queryKey + 1);

  const [showSignout, setShowSignOut] = useState(false);
  const [signOutDisabled, setSignOutDisabled] = useState(false);

  const {
    dialogIsOpen: onHoldDialogIsOpen,
    openDialog: onHoldOpenDialog,
    closeDialog: onHoldCloseDialog,
  } = useDialogState();
  const {
    dialogIsOpen: removeHoldDialogIsOpen,
    openDialog: removeHoldOpenDialog,
    closeDialog: removeHoldCloseDialog,
  } = useDialogState();

  const { showToast } = useToast();
  const patchWithAuth = usePatchWithAuth();

  if (isError) {
    return <Text>{`${error}`}</Text>;
  }
  if (isLoading) {
    return <Text>Loading...</Text>;
  }
  // @rohittalwalkar - useColorQuery might return "undefined"
  if (report === undefined) {
    return <Text>Loading...</Text>;
  }

  return (
    <Container
      sx={(theme) => ({
        position: 'sticky',
        bottom: 0,
        backgroundColor: theme.palette.background.default,
      })}
    >
      <Box
        padding={2}
        boxShadow={3}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        {report.state !== 'manual_hold' && (
          <Button
            disabled={report.state !== 'new'}
            onClick={onHoldOpenDialog}
            variant="color-secondary"
          >
            Put on Hold
          </Button>
        )}
        {report.state === 'manual_hold' && (
          <Button onClick={removeHoldOpenDialog} variant="color-secondary">
            Remove Hold
          </Button>
        )}

        <Button
          disabled={report.state !== 'new' || signOutDisabled}
          onClick={() => {
            setSignOutDisabled(true);
            setShowSignOut(true);
          }}
          variant="color-primary"
        >
          Sign Out Report
        </Button>
      </Box>
      <OnHoldDialog
        isOpen={onHoldDialogIsOpen}
        onSubmit={async (holdReason: string) => {
          onHoldCloseDialog();
          try {
            await patchWithAuth(`/reports/${reportId}`, {
              state: 'manual_hold',
              hold_reason: holdReason,
            });
            showToast('This report has been placed on hold.', { severity: 'success' });
            refetch();
          } catch (errors: any) {
            showToast('We were not able put the report on hold.', { severity: 'error' });
            // TODO @rohittalwalkar - log errors somewhere or show them to the user
          }
        }}
        onCancel={onHoldCloseDialog}
      />
      <SignoutDialog
        isOpen={showSignout}
        onAccept={async () => {
          setShowSignOut(false);
          try {
            await patchWithAuth(`/reports/${reportId}`, { state: 'signed_out' });
            showToast('This report has been signed out.', { severity: 'success' });
            refetch();
          } catch (errors: any) {
            showToast('We were not able to sign out the report.', { severity: 'error' });
            // TODO @rohittalwalkar - log errors somewhere or show them to the user
          } finally {
            setSignOutDisabled(false);
          }
        }}
        onCancel={() => {
          setShowSignOut(false);
          setSignOutDisabled(false);
        }}
      />
      <RemoveHoldDialog
        isOpen={removeHoldDialogIsOpen}
        onAccept={async () => {
          removeHoldCloseDialog();
          try {
            await patchWithAuth(`/reports/${reportId}`, { state: 'new' });
            showToast('This report moved back to Ready for sign out.', { severity: 'success' });
            refetch();
          } catch (errors: any) {
            showToast('We were not able to remove the hold.', { severity: 'error' });
            // TODO @rohittalwalkar - log errors somewhere or show them to the user
          }
        }}
        onCancel={removeHoldCloseDialog}
      />
    </Container>
  );
};
